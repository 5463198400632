<app-layout></app-layout>
<div class="content">

  <!-- <div class="views-header">
    <div class="breadcrmb"><span class="breadcrumb-link" routerLink="/analytics">Events</span> <span> <img
          src="../../../assets/ride_loud_icons/breadcrumb-sep.svg" alt=""> </span><span class="bread-title">
        Events
      </span></div>
  </div> -->
  <div class="skill-tabs d-flex justify-content-between align-items-top">
    <div class="skill-tab-left">
      <br /><br />
      <div class="table-responsive">
        <table class="table mt-2" aria-describedby=" tableDescription">
          <thead>
            <tr>
              <th scope="col">Sl.No.</th>
              <th scope="col">Event Name</th>
              <th scope="col">Event Type</th>
              <th scope="col">Sports Type</th>
              <th scope="col">Sport Stage</th>
              <th scope="col">Rider Terrain</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Start Time</th>
              <th scope="col">End Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let event of eventLists; let i = index">
              <td>{{(page - 1) * itemsPerPage + i + 1}}.</td>
              <td>{{event.eventName ? event.eventName : 'N/A'}}</td>
              <td>{{event.eventType ? event.eventType : 'N/A'}}</td>
              <td>{{event.rideType ? event.rideType : 'N/A'}}</td>
              <td>{{event.sportStage ? event.sportStage : 'N/A'}}</td>
              <td>{{event.riderTerrain ? event.riderTerrain : 'N/A'}}</td>
              <td>{{event.startDate | date: 'dd/MM/yyyy'}}</td>
              <td>{{event?.endDate | date: 'dd/MM/yyyy'}}</td>
              <td>{{event.startTime |date:'shortTime':'UTC'}}</td>
              <td>{{event.endTime |date:'shortTime':'UTC'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="tableDescription" class="sr-only">
        Description of the table content goes here.
      </div>
      <!-- <h3 *ngIf="notFound" style="text-align: center">No result found</h3> -->

      <div class="d-flex justify-content-center mt-4">
        <img src="../../../assets/images/not-found.svg" alt="" *ngIf="notFound">
      </div>
      <pagination *ngIf="!notFound && totalCount > itemsPerPage" [boundaryLinks]="true" [totalItems]="totalCount"
        [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
        previousText="&lsaquo;" nextText="&rsaquo;" [maxSize]="5" firstText="&laquo;" lastText="&raquo;">
      </pagination>
    </div>

  </div>
</div>